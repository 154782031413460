import { IonButton, IonIconButton } from "@components/ionButton";
import {
  AlertColor,
  Box,
  Container,
  Grid,
  Modal,
  Paper,
  Slide,
  Step,
  StepButton,
  StepConnector,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ReactComponent as LeftIcon } from "@assets/icons/chev-left-icon.svg";
import {
  FieldValueTypes,
  fieldNames,
  fields,
  initialValues,
  validationSchema,
  parameterSetField,
  HomeChargerField,
} from "./fields";
import {
  getChargerTemplate,
  getVendorCodes,
  postChargerTemplate,
} from "@nexusAPI/chargerTemplates";
import {
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikValues,
} from "formik";
import IonSnackbar from "@components/ionSnackbar";
import { getErrorMessage } from "@utils/APIInterceptor";
import { getChargingStationsConnector } from "@nexusAPI/connector-types";
import Capabilities from "./steps/Capabilities";
import Evse from "./steps/Evse";
import Connectors from "./steps/Connectors";
import Loader from "@components/loader";
import { ChargerTemplates } from "types/ChargerTemplate";

interface ModalProps {
  modalOpen: boolean;
  handleClose: (event?: any) => void;
  Id?: string;
  Model?: string;
  onChargerTemplateAddCallback?: (editMode?: boolean) => void;
  chargertemplates: ChargerTemplates[];
}
const MODAL_SPACING = 64;
const HEADER_HEIGHT = 66;

const steps = [
  "Model Details",
  "Home Charger",
  "Connector Details",
  "Capabilities",
  "Parameter Set",
];

const ChargerTemplateModal = forwardRef(
  (
    {
      modalOpen,
      handleClose,
      Id,
      Model,
      onChargerTemplateAddCallback,
      chargertemplates,
    }: ModalProps,
    ref
  ) => {
    const theme = useTheme();
    const [loader, setLoader] = useState<boolean>(false);
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<{
      message: string;
      severity?: AlertColor;
    }>({
      message: "",
      severity: undefined,
    });
    const [activeStep, setActiveStep] = useState<number>(0);
    const [isVendorIdExist, setIsVendorIdExist] = useState<string>("");
    const [isVendorModelExist, setIsVendorModelExist] = useState<string>("");
    const [vendorCodeList, setVendorCodeList] =
      useState<Awaited<ReturnType<typeof getVendorCodes>>>();
    const handleStep = (step: number) => () => {
      setActiveStep(step);
    };

    const handleBack = () => {
      setActiveStep((prev) => prev - 1);
    };

    const checkVendorId = (event: string) => {
      const isPresent = chargertemplates.find(
        (template) => template.vendorId === event
      );
      if (isPresent) {
        setIsVendorIdExist("Vendor id is already present");
      } else {
        setIsVendorIdExist("");
      }
    };
    const checkVendorModel = (event: string) => {
      const isPresent = chargertemplates.find(
        (template) => template.vendorModel === event
      );
      if (isPresent) {
        setIsVendorModelExist("Vendor model is already present");
      } else {
        setIsVendorModelExist("");
      }
    };

    const [apiInitialValues, setApiInitialValues] =
      useState<FieldValueTypes>(initialValues);
    const [allConnectors, setAllConnectors] = useState<
      Array<{ label: string; value: string }>
    >([]);

    const getAllConnectors = useCallback(async () => {
      try {
        const data = await getChargingStationsConnector();
        setAllConnectors(
          data.map((connector) => ({
            label: connector.display_text,
            value: connector.type,
          }))
        );
      } catch {}
    }, []);

    const getVendorNameCode = useCallback(async () => {
      try {
        const response = await getVendorCodes();
        if (!response) {
          throw new Error("Error occured");
        }
        setVendorCodeList(response);
      } catch {}
    }, []);

    const getChargerTemplateDetails = useCallback(
      async (vendorId: string, vendorModel: string) => {
        try {
          setLoader(true);
          const data = await getChargerTemplate({ vendorId, vendorModel });
          const chargerTemplatesValues: FieldValueTypes = {
            [fieldNames.vendorId]: data.vendorId || "",
            [fieldNames.vendorName]: data.vendorCode || "",
            [fieldNames.homeCharger]: data.homeCharger || false,
            [fieldNames.vendorModel]: data.vendorModel || "",
            [fieldNames.modelName]: data.modelName || "",
            [fieldNames.qrScanIdentification]:
              data.qrScanIdentification || false,
            [fieldNames.instructionImage]: data.instructionImage || "",
            [fieldNames.textBasedIdentification]:
              data.textBasedIdentification || true,
            [fieldNames.evses]: data.evses.map((evse) => evse) || [],
            [fieldNames.connectors]:
              data.connectors.map((connector) => connector) || [],
            [fieldNames.parameterSet]:
              JSON.stringify(
                data.parameterSet.map((each) => each),
                null,
                2
              ) || [],
            [fieldNames.capabilities]: data.capabilities || {},
            [fieldNames.images]: data.images[0] || "",
          };
          setApiInitialValues(chargerTemplatesValues);
        } catch (err) {
        } finally {
          setLoader(false);
        }
      },
      []
    );

    useEffect(() => {
      if (!!Id && !!Model) {
        getChargerTemplateDetails(Id, Model);
      }
      getAllConnectors();
      getVendorNameCode();
    }, [
      Id,
      Model,
      getChargerTemplateDetails,
      getAllConnectors,
      getVendorNameCode,
    ]);

    const createChargerTemplate = useCallback(
      async (values: FieldValueTypes, id?: string, model?: string) => {
        const {
          vendorId,
          vendorName,
          vendorModel,
          homeCharger,
          modelName,
          qrScanIdentification,
          instructionImage,
          textBasedIdentification,
          connectors,
          images,
          evses,
          parameterSet,
          capabilities,
        } = values;
        try {
          setSubmitLoader(true);
          const response = await postChargerTemplate({
            vendorId,
            vendorCode: vendorName,
            vendorModel,
            homeCharger,
            ...(modelName ? { modelName: modelName } : { modelName: null }),
            ...(homeCharger
              ? { qrScanIdentification: qrScanIdentification }
              : {}),
            ...(homeCharger ? { instructionImage: instructionImage } : {}),
            ...(homeCharger
              ? { textBasedIdentification: textBasedIdentification }
              : {}),
            images: [images],
            capabilities,
            connectors,
            evses,
            parameterSet,
          });
          if (!response) {
            throw new Error(
              "error occured while creating new charger template"
            );
          }
          onChargerTemplateAddCallback &&
            onChargerTemplateAddCallback(!!Id ? true : false);
        } catch (err: any) {
          const message = getErrorMessage(err, "createTemplateCharger");
          setSnackbar({ message, severity: "error" });
        } finally {
          setSubmitLoader(false);
        }
      },
      [Id, onChargerTemplateAddCallback]
    );

    const onFormSubmit = async (
      values: FieldValueTypes,
      actions: FormikHelpers<FieldValueTypes>
    ) => {
      const { connectors, evses, parameterSet } = values;
      const evseMap = new Map();
      evses.forEach((each, index) => {
        evseMap.set(index + 1, false);
      });
      connectors.forEach((connector) => {
        if (evseMap.has(connector.evseId)) {
          evseMap.set(connector.evseId, true);
        }
      });
      let ParamterSetParsed;

      const allEvseHasConnectors = Array.from(evseMap.values()).every(Boolean);
      if (!allEvseHasConnectors) {
        actions.setStatus("Atleast one Connector is required for each Evse");
        return;
      } else {
        actions.setStatus("");
      }
      try {
        ParamterSetParsed = JSON.parse(parameterSet);
        const finalValue = { ...values, parameterSet: ParamterSetParsed };
        if (Id && Model) {
          createChargerTemplate(finalValue, Id, Model);
        } else {
          createChargerTemplate(finalValue);
        }
      } catch (err: any) {
        const message = getErrorMessage(err, "createChargerTemplate");
        setSnackbar({ message, severity: "error" });
      }
    };

    return (
      <Slide in={modalOpen} direction="up" ref={ref} tabIndex={-1}>
        <Container
          disableGutters={true}
          component="main"
          maxWidth="md"
          sx={{
            outline: "none",
            position: "absolute",
            top: MODAL_SPACING,
            left: 0,
            right: 0,
            bottom: MODAL_SPACING,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: 2,
              background: theme.palette.background.paper,
              color: theme.palette.getContrastText(
                theme.palette.background.paper
              ),
              boxShadow: theme.shadows[1],
              overflow: "hidden",
            }}
          >
            <Box>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ flex: 1 }} fontWeight={700} variant="h6">
                  {Id && Model
                    ? "Edit Charger Template"
                    : "Add Charger Template"}
                </Typography>

                <IonIconButton
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                >
                  <CloseIcon fill={"currentColor"} width={24} height={24} />
                </IonIconButton>
              </Box>
              <Stepper
                sx={{
                  px: 2,
                }}
                activeStep={activeStep}
                connector={
                  <StepConnector
                    sx={{
                      "& .MuiStepConnector-line": {
                        width: "80%",
                        margin: "auto",
                      },
                    }}
                  />
                }
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton onClick={handleStep(index)}>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box
              sx={{
                maxHeight: `calc(100vh - ${HEADER_HEIGHT + 2 * MODAL_SPACING}px)`,
                overflowY: "auto",
              }}
            >
              <Box>
                <Formik
                  initialValues={apiInitialValues}
                  validationSchema={validationSchema[activeStep]}
                  onSubmit={onFormSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    status,
                    isValid,
                    isSubmitting,
                    validateForm,
                    setTouched,
                    setFieldValue,
                  }) => {
                    const handleNext = async () => {
                      try {
                        const errors: FormikErrors<FormikValues> =
                          await validateForm();
                        if (Object.keys(errors).length === 0) {
                          setActiveStep((prev) => prev + 1);
                        } else {
                          setTouched(errors);
                        }
                      } catch (err) {}
                    };
                    return (
                      <Form autoComplete="off" id="charger_template_Id">
                        <Grid container spacing={1} p={4}>
                          {activeStep === 0 &&
                            fields.map((item, idx) => {
                              const { colCount, ...fieldProps } = item;
                              if (fieldNames.vendorId === item.name) {
                                return (
                                  <Grid
                                    xs={colCount}
                                    item
                                    key={fieldProps.name}
                                  >
                                    <Field
                                      {...fieldProps}
                                      disabled={!!Id && !!Model}
                                      onChange={checkVendorId}
                                      helperText={isVendorIdExist}
                                    />
                                  </Grid>
                                );
                              }
                              if (fieldNames.vendorModel === item.name) {
                                return (
                                  <Grid
                                    xs={colCount}
                                    item
                                    key={fieldProps.name}
                                  >
                                    <Field
                                      {...fieldProps}
                                      disabled={!!Id && !!Model}
                                      onChange={checkVendorModel}
                                      helperText={isVendorModelExist}
                                    />
                                  </Grid>
                                );
                              }
                              if (fieldNames.vendorName === item.name) {
                                return (
                                  <Grid
                                    xs={colCount}
                                    item
                                    key={fieldProps.name}
                                  >
                                    <Field
                                      {...fieldProps}
                                      disabled={!!Id && !!Model}
                                      options={vendorCodeList?.map(
                                        ({ vendorCode, vendorName }) => ({
                                          label: vendorName,
                                          value: vendorCode,
                                        })
                                      )}
                                    />
                                  </Grid>
                                );
                              }
                              if (item.name === fieldNames.instructionImage) {
                                if (values.homeCharger) {
                                  const { ...fieldProps2 } = fields[idx + 1];
                                  const { ...fieldProps3 } = fields[idx + 2];
                                  return (
                                    <Grid container spacing={2}>
                                      <Grid item xs={6} key={fieldProps.name}>
                                        <Field {...fieldProps} />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={12}
                                            key={fieldProps2.name}
                                          >
                                            <Field {...fieldProps2} />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            key={fieldProps3.name}
                                          >
                                            <Field {...fieldProps3} />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                                return null;
                              }

                              return (
                                <Grid xs={colCount} item key={fieldProps.name}>
                                  <Field {...fieldProps} />
                                </Grid>
                              );
                            })}
                          {activeStep === 1 &&
                            HomeChargerField.map((item, idx) => {
                              const { colCount, ...fielProps } = item || {};
                              if (item.name === fieldNames.homeCharger) {
                                return (
                                  <Grid item xs={colCount}>
                                    <Field {...fielProps} />
                                  </Grid>
                                );
                              }
                              if (
                                item.name === fieldNames.instructionImage ||
                                item.name ===
                                  fieldNames.textBasedIdentification ||
                                item.name === fieldNames.qrScanIdentification
                              ) {
                                if (values.homeCharger) {
                                  if (idx === 1) {
                                    return (
                                      <Grid container>
                                        <Grid
                                          xs={colCount}
                                          item
                                          key={fielProps.name}
                                        >
                                          <Field {...fielProps} />
                                        </Grid>
                                        <Grid item xs={12} mt={2}>
                                          <Typography sx={{ fontWeight: 500 }}>
                                            Charger Id Identification
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                  return (
                                    <Grid
                                      xs={colCount}
                                      item
                                      key={fielProps.name}
                                    >
                                      <Field {...fielProps} />
                                    </Grid>
                                  );
                                }
                                return null;
                              }
                              return (
                                <Grid xs={colCount} item key={fielProps.name}>
                                  <Field {...fielProps} />
                                </Grid>
                              );
                            })}
                          {activeStep === 2 && (
                            <Evse
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          )}
                          {activeStep === 2 && (
                            <Connectors
                              allConnectors={allConnectors}
                              values={values}
                            />
                          )}
                          {/* <FieldArray name={fieldNames.parameterSet}>
                          {({ push, remove }) => {
                            return (
                              <>
                                {values.parameterSet.map(
                                  (parameters, index) => {
                                    return (
                                      <>
                                        {parameterSetField.map((item) => {
                                          const { colCount, ...fieldProps } =
                                            item;
                                          return (
                                            <>
                                              <Grid
                                                item
                                                xs={colCount}
                                                key={fieldProps.name}
                                              >
                                                <Field
                                                  {...fieldProps}
                                                  name={`${fieldNames.parameterSet}[${index}]`}
                                                />
                                                <Button
                                                  onClick={() =>
                                                    push(values.parameterSet[0])
                                                  }
                                                >
                                                  Add ParameterSet
                                                </Button>
                                              </Grid>
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  }
                                )}
                              </>
                            );
                          }}
                        </FieldArray> */}
                          {activeStep === 3 && <Capabilities />}
                          {activeStep === 4 &&
                            parameterSetField.map((item) => {
                              const { colCount, ...fieldProps } = item;
                              return (
                                <>
                                  <Grid
                                    xs={colCount}
                                    item
                                    key={fieldProps.name}
                                  >
                                    <Field {...fieldProps} />
                                  </Grid>
                                </>
                              );
                            })}
                          <Grid item xs={6} />
                          <Grid item xs={6}>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <IonButton
                                  type="button"
                                  variant="outlined"
                                  color="button"
                                  fullWidth
                                  disabled={activeStep === 0}
                                  onClick={() => handleBack()}
                                  sx={(theme) => ({
                                    height: 48,
                                    mt: 2,
                                  })}
                                  aria-label="Submit"
                                >
                                  <LeftIcon />
                                </IonButton>
                              </Grid>
                              <Grid item xs={3}>
                                <IonButton
                                  type="button"
                                  variant="outlined"
                                  color="button"
                                  fullWidth
                                  disabled={
                                    activeStep === 4 ||
                                    (!!isVendorIdExist && !!isVendorModelExist)
                                  }
                                  onClick={() => handleNext()}
                                  sx={(theme) => ({
                                    height: 48,
                                    mt: 2,
                                  })}
                                  aria-label="Submit"
                                >
                                  <LeftIcon
                                    style={{
                                      transform: "rotate(180deg)",
                                    }}
                                  />
                                </IonButton>
                              </Grid>

                              <Grid item xs={6}>
                                <IonButton
                                  type="submit"
                                  variant="contained"
                                  color="button"
                                  fullWidth
                                  disabled={
                                    !isValid ||
                                    isSubmitting ||
                                    submitLoader ||
                                    (!Id && !Model && activeStep < 4)
                                  }
                                  sx={(theme) => ({
                                    height: 48,
                                    mt: 2,
                                  })}
                                  aria-label="Submit"
                                >
                                  {submitLoader ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mt: 1,
                                      }}
                                    >
                                      <Loader size={30} />
                                    </Box>
                                  ) : Id && Model ? (
                                    "Update"
                                  ) : (
                                    "Add"
                                  )}
                                </IonButton>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {status}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Paper>
          <IonSnackbar
            open={Boolean(snackbar.message && snackbar.severity)}
            onClose={() => setSnackbar({ message: "", severity: undefined })}
            severity={snackbar.severity}
            message={snackbar.message}
          />
          <Modal open={loader}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <Loader />
            </Box>
          </Modal>
        </Container>
      </Slide>
    );
  }
);

export default ChargerTemplateModal;
