import { ApiEndpoints, RESTServerRoute } from "../types/Server";

import { nexusAPI } from "@utils/APIInterceptor";
import { Site } from "../types/Site";
import { ActionResponse, SiteDataResult } from "../types/DataResult";
import { getTenantID } from "@utils/authService";
import { siteImage } from "../types/siteImage";

export interface SitePayload {
  id: string;
  WithCompany?: boolean;
  WithImage?: boolean;
}

async function getSites(payload: {
  Issuer?: boolean;
  WithCompany?: boolean;
  Limit?: number;
  Search?: string;
  Skip?: number;
  SortFields?: string;
  OnlyRecordCount?: boolean;
  ExcludeSitesOfUserID?: string;
  WithAvailableChargingStations?: boolean;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<SiteDataResult>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_SITES}`,
      {
        params: payload,
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function updateSiteOwner(
  payload: {
    siteId: string;
  },
  body: {
    siteOwner: boolean;
    userID: string;
  }
) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { siteId } = payload;
    if (!siteId) {
      throw new Error("site Id not found");
    }
    const response = await nexusAPI(token).put<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_SITES}/${siteId}/users/owner`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function updateSiteUserAdmin(
  payload: {
    siteId: string;
  },
  body: {
    siteAdmin: boolean;
    userID: string;
  }
) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { siteId } = payload;
    if (!siteId) {
      throw new Error("site Id not found");
    }
    const response = await nexusAPI(token).put<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_SITES}/${siteId}/users/admin`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getSitebyID(payload: SitePayload) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id, ...params } = payload || {};
    const response = await nexusAPI(token).get<Site>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_SITES}/${id}`,
      {
        params: params,
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getSiteImage(payload: { id: string }) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const tenantID = getTenantID();
    if (!tenantID) {
      throw new Error("tenantID not found");
    }
    const { id } = payload || {};
    const response = await nexusAPI(token).get<siteImage>(
      `${ApiEndpoints.REST_UTIL_API}/${RESTServerRoute.REST_SITES}/${id}/image`,
      {
        params: { TenantID: tenantID },
      }
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function postSite(body: {
  address: {
    address1: string;
    address2: string;
    city: string;
    coordinates: number[];
    country: string;
    department: string;
    postalCode: string;
    region: string;
  };
  autoUserSiteAssignment: boolean;
  company: string;
  companyID: string;
  id: string;
  image: string;
  issuer: boolean;
  name: string;
  public: boolean;
  tariffID: string | null;
  publish: boolean;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).post<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_SITES}`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function putSite(body?: {
  address: {
    address1?: string;
    address2?: string;
    city?: string;
    coordinates?: number[];
    country?: string;
    department?: string;
    postalCode?: string;
    region?: string;
  };
  autoUserSiteAssignment?: boolean;
  company?: string;
  companyID: string;
  id: string;
  image?: string;
  issuer?: boolean;
  name: string;
  public?: boolean;
  tariffID?: string | null;
  publish?: boolean;
}) {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { id } = body || {};
    if (!id) {
      throw new Error("Site ID Not Found");
    }
    const response = await nexusAPI(token).put<ActionResponse>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.REST_SITES}/${id}`,
      body
    );
    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
}

export {
  getSites,
  getSitebyID,
  getSiteImage,
  putSite,
  postSite,
  updateSiteOwner,
  updateSiteUserAdmin,
};
