import TextField from "./TextField";
import * as Yup from "yup";
import RadioGroupField from "./RadioGroupField";
import { Capabilities, Connectors, Evse } from "types/ChargerTemplate";
import { Typography } from "@mui/material";
import { CustomCheckbox } from "./steps/Capabilities";
import ImageField from "./ImageField";
import { CheckBox } from "./CheckBox";
import IonageTooltip from "@components/ionTooltip";
import { ReactComponent as InfoIcon } from "@assets/materialIcons/info.svg";
import CustomSwitch from "./CustomSwitch";

export enum fieldNames {
  vendorId = "vendorId",
  vendorModel = "vendorModel",
  vendorName = "vendorName",
  capabilities = "capabilities",
  images = "images",
  homeCharger = "homeCharger",
  modelName = "modelName",
  instructionImage = "instructionImage",
  qrScanIdentification = "qrScanIdentification",
  textBasedIdentification = "textBasedIdentification",
  evses = "evses",
  connectors = "connectors",
  parameterSet = "parameterSet",
}

export enum evseFieldNames {
  currentType = "currentType",
  maxPower = "maxPower",
  maxVoltage = "maxVoltage",
  maxAmperage = "maxAmperage",
  physicalReference = "physicalReference",
  numberOfPhases = "numberOfPhases",
  cannotChargeInParallel = "cannotChargeInParallel",
}

export enum connectorFieldNames {
  type = "type",
  evseId = "evseId",
  maxPower = "maxPower",
  maxVoltage = "maxVoltage",
  maxAmperage = "maxAmperage",
  physicalReference = "physicalReference",
  numberOfPhases = "numberOfPhases",
}

export interface FieldValueTypes {
  [fieldNames.vendorId]: string;
  [fieldNames.vendorModel]: string;
  [fieldNames.vendorName]: string;
  [fieldNames.homeCharger]: boolean;
  [fieldNames.modelName]: string;
  [fieldNames.images]: string;
  [fieldNames.instructionImage]?: string;
  [fieldNames.qrScanIdentification]?: boolean;
  [fieldNames.textBasedIdentification]?: boolean;
  [fieldNames.capabilities]?: Capabilities;
  [fieldNames.evses]: Evse[];
  [fieldNames.connectors]: Connectors[];
  [fieldNames.parameterSet]: any;
}

const commonProps = {
  color: "field",
  variant: "outlined",
};

const fields = [
  {
    name: fieldNames.vendorId,
    placeholder: "Enter Vendor Id",
    component: TextField,
    textTransform: "uppercase",
    label: "Vendor ID",
    colCount: 6,
    suffix: (
      <IonageTooltip
        placement="bottom-start"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [5, -5],
              },
            },
          ],
        }}
        title="Expected chargePointVendor in OCPP BootNotification request"
      >
        <InfoIcon
          fill="currentColor"
          width={16}
          height={16}
          style={{ opacity: 0.8, marginLeft: "8px" }}
        />
      </IonageTooltip>
    ),
    ...commonProps,
  },
  {
    name: fieldNames.vendorModel,
    placeholder: "Enter Vendor Model",
    component: TextField,
    textTransform: "uppercase",
    label: "Vendor Model",
    colCount: 6,
    suffix: (
      <IonageTooltip
        placement="bottom-start"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [5, -5],
              },
            },
          ],
        }}
        title="Expected chargePointModel in OCPP BootNotification request"
      >
        <InfoIcon
          fill="currentColor"
          width={16}
          height={16}
          style={{ opacity: 0.8, marginLeft: "8px" }}
        />
      </IonageTooltip>
    ),
    ...commonProps,
  },
  {
    name: fieldNames.vendorName,
    placeholder: "Enter Vendor Name",
    component: TextField,
    label: "Vendor Name",
    colCount: 6,
    ...commonProps,
    select: true,
  },
  {
    name: fieldNames.modelName,
    placeholder: "e.g., EVLink Pro AC",
    component: TextField,
    label: "Model Name",
    colCount: 6,
    ...commonProps,
  },
  {
    name: fieldNames.images,
    placeholder: "Upload Image",
    component: ImageField,
    colCount: 12,
    minHeight: "230px",
    aspectRatio: "3:4",
    ...commonProps,
  },
];

const HomeChargerField = [
  {
    name: fieldNames.homeCharger,
    component: CustomSwitch,
    label: (
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        Add Home Charging Capability
      </Typography>
    ),
    colCount: 12,
    ...commonProps,
  },
  {
    name: fieldNames.instructionImage,
    placeholder: "Upload Instruction Image",
    component: ImageField,
    colCount: 12,
    ...commonProps,
  },
  {
    name: fieldNames.qrScanIdentification,
    component: CheckBox,
    label: <Typography sx={{ fontSize: "16px" }}>QR Scan</Typography>,
    colCount: 4,
    ...commonProps,
  },
  {
    name: fieldNames.textBasedIdentification,
    component: CheckBox,
    label: <Typography sx={{ fontSize: "16px" }}>Text Based</Typography>,
    colCount: 4,
    ...commonProps,
  },
];

const evseField = [
  {
    name: evseFieldNames.currentType,
    placeholder: "Enter Current Type",
    component: RadioGroupField,
    label: "Current Type",
    options: [
      { label: "DC", value: "DC" },
      { label: "AC", value: "AC" },
    ],
    radioGroup: true,
    colCount: 3,
    ...commonProps,
  },
  {
    name: evseFieldNames.maxPower,
    placeholder: "Enter maximum power",
    component: TextField,
    label: "Max Power",
    colCount: 3,
    ...commonProps,
    suffix: <Typography>kW</Typography>,
    type: "number",
  },
  {
    name: evseFieldNames.maxVoltage,
    placeholder: "Enter Maximum Voltage",
    component: TextField,
    ...commonProps,
    label: "Max Voltage",
    colCount: 3,
    suffix: <Typography>V</Typography>,
    type: "number",
  },
  {
    name: evseFieldNames.maxAmperage,
    placeholder: "Enter Maximum Ampere",
    component: TextField,
    label: "Max Ampere",
    colCount: 3,
    ...commonProps,
    suffix: <Typography>A</Typography>,
    type: "number",
  },
  {
    name: evseFieldNames.physicalReference,
    placeholder: "Enter Physical refrence",
    component: TextField,
    label: "Physical Refrence",
    colCount: 4,
    ...commonProps,
  },
  {
    name: evseFieldNames.numberOfPhases,
    placeholder: "Enter Number of Phases",
    component: TextField,
    label: "Number of Phases",
    colCount: 3,
    ...commonProps,
  },
];

export const connectorsField = [
  {
    name: connectorFieldNames.type,
    placeholder: "Enter Type",
    component: TextField,
    label: "Connector Type",
    colCount: 3,
    ...commonProps,
    select: true,
  },
  {
    name: connectorFieldNames.evseId,
    placeholder: "Select EVSE Id",
    component: TextField,
    ...commonProps,
    label: "EVSE",
    select: true,
    colCount: 1.5,
  },
  {
    name: connectorFieldNames.maxPower,
    placeholder: "Enter Maximum Power",
    component: TextField,
    label: "Max Power",
    colCount: 2.5,
    ...commonProps,
    suffix: <Typography>kW</Typography>,
    type: "number",
  },
  {
    name: connectorFieldNames.maxVoltage,
    placeholder: "Enter Maximum Voltage",
    component: TextField,
    ...commonProps,
    label: "Max Voltage",
    colCount: 2.5,
    suffix: <Typography>V</Typography>,
    type: "number",
  },
  {
    name: connectorFieldNames.maxAmperage,
    placeholder: "Enter Maximum Ampere",
    component: TextField,
    label: "Max Ampere",
    colCount: 2.5,
    ...commonProps,
    suffix: <Typography>A</Typography>,
    type: "number",
  },
];

export const capabilitiesField = {
  name: fieldNames.capabilities,
  component: CustomCheckbox,
  colCount: 4,
  ...commonProps,
};

export const parameterSetField = [
  {
    name: fieldNames.parameterSet,
    placeholder: "Enter Parameter Set",
    label: "ParameterSet",
    component: TextField,
    ...commonProps,
    colCount: 12,
    maxRows: 12,
    multiline: true,
  },
];

const capabilitiesInitialValue: Capabilities = {
  supportChargingProfiles: true,
  supportRemoteStartStopTransaction: false,
  supportReservation: false,
  supportRFIDCard: false,
  supportUnlockConnector: false,
  supportFirmwareUpgrade: false,
  supportStaticLimitation: false,
};

const parameterSetInitialValue = `[
  {
    "supportedFirmwares": [""
    ],
    "supportedOcppVersions": [
      "1.6"
    ],
    "ocppStandardParameters": {
      "AllowOfflineTxForUnknownId": "0",
      "AuthorizationCacheEnabled": "0",
      "AuthorizeRemoteTxRequests": "0",
      "ClockAlignedDataInterval": "0",
      "ConnectionTimeOut": "0",
      "ConnectorPhaseRotation": "0",
      "HeartbeatInterval": "0",
      "LocalAuthListEnabled": "0",
      "LocalAuthorizeOffline": "0",
      "LocalPreAuthorize": "0",
      "MeterValuesAlignedData": "0",
      "MeterValueSampleInterval": "0",
      "MeterValuesSampledData": "0",
      "ResetRetries": "0",
      "StopTransactionOnEVSideDisconnect": "0",
      "StopTransactionOnInvalidId": "0",
      "StopTxnAlignedData": "0",
      "StopTxnSampledData": "0",
      "TransactionMessageAttempts": "0",
      "TransactionMessageRetryInterval": "0",
      "UnlockConnectorOnEVSideDisconnect": "0",
      "WebSocketPingInterval": "0",
      "GetConfigurationMaxKeys": "0",
      "NumberOfConnectors": "0",
      "SupportedFeatureProfiles": "0",
      "LocalAuthListMaxLength": "0",
      "SendLocalListMaxLength": "0",
      "ChargeProfileMaxStackLevel": "0",
      "ChargingScheduleAllowedChargingRateUnit": "0",
      "ChargingScheduleMaxPeriods": "0",
      "MaxChargingProfilesInstalled": "0"
    },
    "ocppVendorParameters": {}
  }
]`;

const evseInitialValues: Evse[] = [
  {
    id: 1,
    [evseFieldNames.currentType]: "",
    [evseFieldNames.maxPower]: 0,
    [evseFieldNames.maxVoltage]: 0,
    [evseFieldNames.maxAmperage]: 0,
    [evseFieldNames.physicalReference]: "",
    [evseFieldNames.numberOfPhases]: 0,
    [evseFieldNames.cannotChargeInParallel]: false,
  },
];

const connectorsInitialValue: Connectors[] = [
  {
    id: 1,
    [connectorFieldNames.type]: "",
    [connectorFieldNames.evseId]: 1,
    [connectorFieldNames.physicalReference]: "",
    [connectorFieldNames.numberOfPhases]: 0,
    [connectorFieldNames.maxPower]: 0,
    [connectorFieldNames.maxVoltage]: 0,
    [connectorFieldNames.maxAmperage]: 0,
  },
];

const initialValues: FieldValueTypes = {
  [fieldNames.vendorId]: "",
  [fieldNames.vendorName]: "",
  [fieldNames.vendorModel]: "",
  [fieldNames.modelName]: "",
  [fieldNames.homeCharger]: false,
  [fieldNames.images]: "",
  [fieldNames.instructionImage]: "",
  [fieldNames.qrScanIdentification]: false,
  [fieldNames.textBasedIdentification]: true,
  [fieldNames.capabilities]: capabilitiesInitialValue,
  [fieldNames.evses]: evseInitialValues,
  [fieldNames.connectors]: connectorsInitialValue,
  [fieldNames.parameterSet]: parameterSetInitialValue,
};

const validationSchema = [
  Yup.object({
    [fieldNames.vendorId]: Yup.string().required("Vendor Id is required field"),
    [fieldNames.vendorName]: Yup.string().required("Vendor is required field"),
    [fieldNames.vendorModel]: Yup.string().required(
      "Vendor Model is required field"
    ),
  }),
  Yup.object({
    [fieldNames.instructionImage]: Yup.string().when(fieldNames.homeCharger, {
      is: true,
      then: Yup.string().required("Instruction field is required"),
      otherwise: Yup.string(),
    }),
  }),
  Yup.object({
    [fieldNames.evses]: Yup.array().of(
      Yup.object().shape({
        [evseFieldNames.currentType]: Yup.string().required(
          "Current Type is required field"
        ),
        [evseFieldNames.maxAmperage]: Yup.number()
          .min(1, "Ampere must be greater than 0")
          .required("Ampere is required field"),
        [evseFieldNames.maxVoltage]: Yup.number()
          .min(1, "Voltage must be greater than 0")
          .required("Voltage is required field"),
        [evseFieldNames.maxPower]: Yup.number()
          .min(1, "Power must be greater than 0")
          .required("Max Power is required field"),
      })
    ),
    [fieldNames.connectors]: Yup.array().of(
      Yup.object().shape({
        [connectorFieldNames.type]: Yup.string().required(
          "Connector Type is required field"
        ),
        [connectorFieldNames.evseId]: Yup.number().required(
          "EVSE Id id required field"
        ),
      })
    ),
  }),
  Yup.object({}),
  Yup.object({
    [fieldNames.parameterSet]: Yup.string().required(
      "Paramater Set is required field"
    ),
  }),
];

export {
  fields,
  HomeChargerField,
  evseField,
  initialValues,
  evseInitialValues,
  connectorsInitialValue,
  validationSchema,
};
