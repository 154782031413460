import { Field, FieldArray } from "formik";
import React, { FC, useCallback } from "react";
import { FieldValueTypes, evseField, fieldNames } from "../fields";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { IonButton } from "@components/ionButton";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/delete.svg";

interface EvseProps {
  values: FieldValueTypes;
  setFieldValue: (field: string, value: any) => void;
}

const Evse: FC<EvseProps> = ({ values, setFieldValue }) => {
  const theme = useTheme();

  const handleEvseRemovedIds = useCallback(
    (evseId: number) => {
      const { connectors } = values || {};
      connectors.forEach((connector, index) => {
        if (connector.evseId === evseId) {
          setFieldValue(`${fieldNames.connectors}[${index}].evseId`, null);
        }
      });
    },
    [setFieldValue, values]
  );

  return (
    <FieldArray name={fieldNames.evses}>
      {({ push, remove }) => (
        <>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: "1rem",
              }}
            >
              <Typography variant="h6" fontSize={18} ml={-2}>
                EVSE
              </Typography>
              <IonButton
                variant="contained"
                color="button"
                onClick={() => {
                  push({
                    id: values.evses.length + 1,
                    currentType: "",
                    maxPower: 0,
                    maxVoltage: 0,
                    maxAmperage: 0,
                    cannotChargeInParallel: false,
                  });
                  if (values.evses.length >= values.connectors.length) {
                    values.connectors.push({
                      id: values.connectors.length + 1,
                      type: "",
                      evseId: values.evses.length + 1,
                      physicalReference: "",
                      numberOfPhases: 0,
                      maxPower: 0,
                      maxVoltage: 0,
                      maxAmperage: 0,
                    });
                  }
                }}
                sx={{
                  whiteSpace: "nowrap",
                  "& .MuiButton-startIcon": {
                    marginLeft: 0,
                    marginRight: 0,
                  },
                }}
                startIcon={
                  <POIAdd fill="currentColor" height={24} width={24} />
                }
              ></IonButton>
            </Box>
          </Grid>
          {values.evses.map((evseItem: any, index: number) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                gap: 4,
              }}
            >
              <Typography
                sx={{
                  mb: "1rem",
                  backgroundColor: theme.palette.primary.main,
                  width: "40px",
                  height: "24px",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.main
                  ),
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "99px",
                }}
              >
                <span>{index + 1}</span>
              </Typography>

              <Box sx={{ display: "flex" }}>
                <Grid container spacing={1} mb={4} key={evseItem.id}>
                  {evseField.map((item) => {
                    const { colCount, ...fieldProps } = item;
                    return (
                      <Grid
                        xs={colCount}
                        item
                        key={`${fieldNames.evses}[${index}].${fieldProps.name}`}
                      >
                        <Field
                          {...fieldProps}
                          name={`${fieldNames.evses}[${index}].${fieldProps.name}`}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                  <Button
                    onClick={() => {
                      if (index >= 1) remove(index);
                      for (let i = index; i < values.evses.length; i++) {
                        values.evses[i].id = i;
                      }
                      handleEvseRemovedIds(index + 1);
                    }}
                    disabled={index === 0}
                  >
                    <DeleteIcon fill="currentColor" />
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </>
      )}
    </FieldArray>
  );
};

export default Evse;
