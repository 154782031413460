import "@fontsource/dm-sans/latin-400.css";
import "@fontsource/dm-sans/latin-500.css";
import "@fontsource/dm-sans/latin-700.css";
import "@fontsource/dm-mono/latin-400.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "@config/storeConfig";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Routes from "@config/routes";
import { RootTheme } from "@config/theme/rootTheme";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import ErrorBoundary from "@components/errorBoundary";
import styled from "@emotion/styled";
import "./index.css";
// import { datadogRum } from "@datadog/browser-rum";
import { getTenantbySubdomain } from "@nexusAPI/tenants";
import NotFound from "@screens/notFound";
import Loader from "@components/loader";

const container = document.getElementById("root")!;
const root = createRoot(container);

const WHITELISTED_DOMAINS = ["nexus"];

const MainContainer = styled.div(() => ({
  minHeight: "100vh",
  display: "flex",
}));

const App = () => {
  const [unregisteredOperator, setUnregisteredOperator] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    //make function to prevent install prompt
    const stopInstallPrompt = (event: Event) => {
      event.preventDefault();
    };
    window.addEventListener("beforeinstallprompt", stopInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", stopInstallPrompt);
    };
  }, []);

  const getTenantBySubdomainData = React.useCallback(async () => {
    try {
      setLoading(true);
      const HOSTNAME = window.location.hostname || "";
      const [provider_name] = HOSTNAME?.split(".") || "";
      if (
        provider_name &&
        provider_name !== "localhost" &&
        !WHITELISTED_DOMAINS.includes(provider_name)
      ) {
        await getTenantbySubdomain();
      }
    } catch (error: any) {
      const { status } = error || {};
      if (status === 404) {
        setUnregisteredOperator(true);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getTenantBySubdomainData();
  }, [getTenantBySubdomainData]);

  return (
    <ThemeProvider theme={RootTheme}>
      <CssBaseline />
      <Provider store={store}>
        <MainContainer>
          <ErrorBoundary>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </Box>
            ) : unregisteredOperator ? (
              <NotFound
                disableNavigation={true}
                message="Operator does not exist."
              />
            ) : (
              <Routes />
            )}
          </ErrorBoundary>
        </MainContainer>
      </Provider>
    </ThemeProvider>
  );
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// datadogRum.init({
//   applicationId: "a72b8d3d-426f-401c-ad52-37b1b9ca9bd0",
//   clientToken: "pub888c02ab1c9d9cc9d4926c342433b9fc",
//   site: "us5.datadoghq.com",
//   service: "nexus-dashboard",
//   env: process.env.REACT_APP_ENV,
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 20,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: "mask-user-input",
// });
