import { Field, FieldArray } from "formik";
import React, { FC } from "react";
import {
  FieldValueTypes,
  connectorFieldNames,
  connectorsField,
  fieldNames,
} from "../fields";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { IonButton } from "@components/ionButton";

import { ReactComponent as DeleteIcon } from "@assets/icons/delete.svg";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";

interface ConnectorsProps {
  values: FieldValueTypes;
  allConnectors: Array<{ label: string; value: string }>;
}
const Connectors: FC<ConnectorsProps> = ({ values, allConnectors }) => {
  const evsesOptions = values.evses.map((item, index) => {
    return { label: index + 1, value: index + 1 };
  });
  const theme = useTheme();
  return (
    <FieldArray name={fieldNames.connectors}>
      {({ push, remove }) => {
        return (
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mb: "1rem",
                }}
              >
                <Typography variant="h6" fontSize={18} ml={-2}>
                  Connectors
                </Typography>
                <IonButton
                  variant="contained"
                  color="button"
                  sx={{
                    whiteSpace: "nowrap",
                    "& .MuiButton-startIcon": {
                      marginLeft: 0,
                      marginRight: 0,
                    },
                  }}
                  startIcon={
                    <POIAdd fill="currentColor" height={24} width={24} />
                  }
                  onClick={() => {
                    push({
                      id: values.connectors.length + 1,
                      evseId: values.evses.length,
                    });
                  }}
                />
              </Box>
            </Grid>
            {values.connectors.map((connector, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: 4,
                }}
              >
                <Typography
                  sx={{
                    mb: "1rem",
                    backgroundColor: theme.palette.primary.main,
                    height: "24px",
                    width: "24px",
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "99px",
                  }}
                >
                  <span>{index + 1}</span>
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Grid container mb={4} spacing={1} key={connector.id}>
                    {connectorsField.map((item) => {
                      const { colCount, ...fieldProps } = item;
                      if (fieldProps.name === connectorFieldNames.evseId) {
                        return (
                          <Grid
                            xs={colCount}
                            item
                            key={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                            mb={1}
                          >
                            <Field
                              {...fieldProps}
                              options={evsesOptions || []}
                              name={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                            />
                          </Grid>
                        );
                      }
                      if (fieldProps.name === connectorFieldNames.type) {
                        return (
                          <Grid
                            xs={colCount}
                            item
                            key={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                            mb={1}
                          >
                            <Field
                              {...fieldProps}
                              options={allConnectors || []}
                              name={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                            />
                          </Grid>
                        );
                      }
                      return (
                        <Grid
                          xs={colCount}
                          item
                          key={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                          mb={1}
                        >
                          <Field
                            {...fieldProps}
                            name={`${fieldNames.connectors}[${index}].${fieldProps.name}`}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                    <Button
                      onClick={() => {
                        remove(index);
                      }}
                      disabled={index === 0}
                    >
                      <DeleteIcon fill="currentColor" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        );
      }}
    </FieldArray>
  );
};

export default Connectors;
