import IonTable from "@components/ionTable";
import IonageTooltip from "@components/ionTooltip";
import { AlertColor, Box, Modal, Typography, useTheme } from "@mui/material";
import {
  getChargerTemplates,
  getVendorCodes,
} from "@nexusAPI/chargerTemplates";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as EditIcon } from "@assets/icons/pencil-icon.svg";
import { IonButton, IonButtonMultiVariant } from "@components/ionButton";
import { ReactComponent as Check } from "@assets/icons/check.svg";
import ChargerTemplateModal from "./ChargerTemplateModal";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ConnectorType } from "../../types/ChargingStation";
import { ReactComponent as TYPE_1Icon } from "@assets/images/connectors/TYPE_1.svg";
import { ReactComponent as TYPE_2Icon } from "@assets/images/connectors/TYPE_2.svg";
import { ReactComponent as CCSIcon } from "@assets/images/connectors/CCS.svg";
import { ReactComponent as CHADEMOIcon } from "@assets/images/connectors/CHADEMO.svg";
import { ReactComponent as UNKNOWNIcon } from "@assets/images/connectors/UNKNOWN.svg";
import { ReactComponent as DOMESTIC_THREE_PINIcon } from "@assets/images/connectors/DOMESTIC_THREE_PIN.svg";
import { ReactComponent as BHARAT_DC_001Icon } from "@assets/images/connectors/BHARAT_DC_001.svg";
import { ReactComponent as BHARAT_AC_001_THREEIcon } from "@assets/images/connectors/BHARAT_AC_001_THREE.svg";
import { ReactComponent as BHARAT_AC_001_SINGLEIcon } from "@assets/images/connectors/BHARAT_AC_001_SINGLE.svg";
import IonSnackbar from "@components/ionSnackbar";
import { getChargingStationsConnector } from "@nexusAPI/connector-types";
import TextField from "./TextField";
import TableLoader from "@components/tableLoader";
import { handleCloseModal } from "@utils/utils";
const TABLE_LIMIT = 50;
const getConnectorIcon = (type?: ConnectorType) => {
  switch (type) {
    case ConnectorType.TYPE_1:
      return TYPE_1Icon;
    case ConnectorType.TYPE_2:
      return TYPE_2Icon;
    case ConnectorType.CCS:
      return CCSIcon;
    case ConnectorType.CHADEMO:
      return CHADEMOIcon;
    case ConnectorType.UNKNOWN:
      return UNKNOWNIcon;
    case ConnectorType.DOMESTIC_THREE_PIN:
      return DOMESTIC_THREE_PINIcon;
    case ConnectorType.BHARAT_DC_001:
      return BHARAT_DC_001Icon;
    case ConnectorType.BHARAT_AC_001_THREE:
      return BHARAT_AC_001_THREEIcon;
    case ConnectorType.BHARAT_AC_001_SINGLE:
      return BHARAT_AC_001_SINGLEIcon;
    default:
      return UNKNOWNIcon;
  }
};
function ChargerTemplatesTable() {
  const theme = useTheme();
  const scrollParentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrolledIndex, setScrolledIndex] = useState<number>(0);
  const [chargertemplates, setChargerTemplates] = useState<
    Awaited<ReturnType<typeof getChargerTemplates>>["result"]
  >([]);

  const CONNECTORS_LOCAL_STORAGE = localStorage.getItem("Connectors");
  const CONNECTORS: Awaited<ReturnType<typeof getChargingStationsConnector>> =
    CONNECTORS_LOCAL_STORAGE?.length
      ? JSON.parse(CONNECTORS_LOCAL_STORAGE)
      : [];

  const [snackbar, setSnackbar] = useState<{
    message: string;
    severity?: AlertColor;
  }>({
    message: "",
    severity: undefined,
  });
  const [updateChargerTemplateModal, setUpdateChargerTemplateModal] =
    useState<boolean>(false);

  const [vendorId, setVendorId] = useState<string>("");
  const [vendorModel, setVendorModel] = useState<string>("");
  const handleCloseChargerTemplateModal = () => {
    setUpdateChargerTemplateModal(false);
  };
  const [selectedVendor, setSelectedVendor] = useState<string>("");
  const [vendorCodesList, setVendorCodesList] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [chargerTemplateLoading, setChargerTemplateLoading] =
    useState<boolean>(false);

  const resetTable = () => {
    setChargerTemplates([]);
    setScrolledIndex(0);
  };

  const selectVendorHandle = (event: string) => {
    setSelectedVendor(event || "");
  };
  const onChargerTemplateAdd = (vendorId?: string, vendorModel?: string) => {
    if (vendorId && vendorModel) {
      setVendorId(vendorId);
      setVendorModel(vendorModel);
    } else {
      setVendorId("");
      setVendorModel("");
    }
    setUpdateChargerTemplateModal(true);
  };

  const getAllVendorCodes = useCallback(async () => {
    try {
      const response = await getVendorCodes();
      if (!response) {
        throw new Error("Error while fetching vendor codes");
      }
      setVendorCodesList(
        response.map((res) => ({
          label: res.vendorName,
          value: res.vendorCode,
        }))
      );
    } catch (err: any) {}
  }, []);

  const onChargerTemplateAddCallback = (editMode?: boolean) => {
    resetTable();
    getChargerTemplatesData();
    setSnackbar({
      message: `Charger template ${editMode ? "updated" : "added"} successfully`,
      severity: "success",
    });
    handleCloseChargerTemplateModal();
  };

  const getChargerTemplatesData = useCallback(
    async (filters?: Parameters<typeof getChargerTemplates>[0]) => {
      try {
        setChargerTemplateLoading(true);
        const data = await getChargerTemplates(filters);
        const { result } = data || {};
        setChargerTemplates(result || []);
      } catch (err) {
      } finally {
        setChargerTemplateLoading(false);
      }
    },
    []
  );
  useEffect(() => {
    getChargerTemplatesData({
      ...(selectedVendor ? { Search: selectedVendor } : {}),
    });
  }, [getChargerTemplatesData, selectedVendor]);

  useEffect(() => {
    getAllVendorCodes();
  }, [getAllVendorCodes]);

  return (
    <>
      <Box sx={{ display: "flex", flex: 1 }}>
        <Box
          sx={{
            flex: 1,
            m: 2,
            display: "flex",
            flexDirection: "column",
            background: theme.palette.background.card,
            color: theme.palette.getContrastText(theme.palette.background.card),
            borderRadius: 2,
            overflow: "hidden",
            boxShadow: theme.shadows[1],
          }}
        >
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 2,
              px: 2,
              backgroundColor: theme.palette.background.tableHead,
              color: theme.palette.getContrastText(
                theme.palette.background.tableHead
              ),
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                flex: 1,
              }}
            >
              <TextField
                sx={{
                  width: 480,
                  "& .MuiOutlinedInput-root": {
                    background: "#fff",
                  },
                }}
                size="small"
                label="Select Vendor"
                select={true}
                value={selectedVendor}
                options={vendorCodesList}
                onChange={selectVendorHandle}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IonButton
                color={"button"}
                variant="contained"
                sx={{ px: 2, ml: 2, whiteSpace: "nowrap" }}
                startIcon={
                  <POIAdd fill="currentColor" height={24} width={24} />
                }
                onClick={() => onChargerTemplateAdd()}
              >
                Add Charger Template
              </IonButton>
            </Box>
          </Box>
          <Box sx={{ flex: 1, position: "relative" }}>
            <div
              ref={scrollParentRef}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: "auto",
              }}
            >
              <IonTable
                tableVirtuosoProps={{
                  useWindowScroll: true,
                  customScrollParent: scrollParentRef.current || undefined,
                  endReached: (index) => {
                    if ((index + 1) % TABLE_LIMIT === 0) {
                      setScrolledIndex(index + 1);
                    }
                  },
                }}
                tableProps={{
                  stickyHeader: true,
                  size: "small",
                }}
                columns={[
                  { label: "Vendor", key: "vendorName" },
                  {
                    label: "Vendor ID",
                    key: "vendorId",
                  },
                  {
                    label: "Vendor Model",
                    key: "vendorModel",
                  },
                  { label: "Connectors", key: "connectors" },
                  {
                    label: "Home Charging Support ",
                    key: "homeChargingSupport",
                  },
                  {
                    label: "",
                    key: "actions",
                    width: "120px",
                  },
                ]}
                rows={chargertemplates.map((chargertemplate) => {
                  const {
                    vendorId = "",
                    vendorModel = "",
                    vendorName = "",
                    homeCharger = false,
                    connectorType = [],
                  } = chargertemplate;
                  return {
                    rowsx: {
                      "& .actions_button": {
                        visibility: "hidden",
                        opacity: 0,
                        transition:
                          "visibility 0.2s linear,opacity 0.2s linear",
                      },
                      "&:hover": {
                        "& .actions_button": {
                          visibility: "visible",
                          opacity: 1,
                        },
                      },
                    },
                    vendorId: (
                      <Box
                        sx={{
                          display: "flex",
                          width: "160px",
                        }}
                      >
                        <Typography fontSize={14} noWrap={true}>
                          {vendorId}
                        </Typography>
                      </Box>
                    ),
                    vendorModel: (
                      <Box
                        sx={{
                          display: "flex",
                          width: "160px",
                        }}
                      >
                        <Typography fontSize={14} noWrap={true}>
                          {vendorModel}
                        </Typography>
                      </Box>
                    ),
                    vendorName: (
                      <Box sx={{ display: "flex", width: "160px" }}>
                        <Typography fontSize={14} noWrap={true}>
                          {vendorName}
                        </Typography>
                      </Box>
                    ),
                    homeChargingSupport: (
                      <Box sx={{ display: "flex" }}>
                        {homeCharger ? (
                          <Check fill="currentColor" width={24} height={24} />
                        ) : (
                          <CloseIcon
                            fill={"currentColor"}
                            width={24}
                            height={24}
                          />
                        )}
                      </Box>
                    ),
                    connectors: (
                      <Box sx={{ display: "flex", columnGap: 1 }}>
                        <Box sx={{ display: "flex" }}>
                          {connectorType.slice(0, 2).map((type) => {
                            const Icon = getConnectorIcon(
                              type as ConnectorType
                            );
                            const display_text = CONNECTORS.find(
                              (connector) => connector.type === type
                            )?.display_text;
                            return (
                              <Box key={display_text} sx={{ mx: 0.5 }}>
                                <IonageTooltip
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, 0],
                                        },
                                      },
                                    ],
                                  }}
                                  title={display_text || "Unknown"}
                                  placement="bottom"
                                >
                                  <Icon
                                    fill={"currentColor"}
                                    height={24}
                                    width={24}
                                  />
                                </IonageTooltip>
                              </Box>
                            );
                          })}
                          {connectorType.length > 2 && (
                            <Box sx={{ mx: 0.5 }}>
                              <Typography fontSize={14}>...</Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ),
                    actions: (
                      <Box
                        className="actions_button"
                        flex={1}
                        display={"flex"}
                        gap={"0.5rem"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <IonButtonMultiVariant
                          color="button"
                          onClick={() =>
                            onChargerTemplateAdd(vendorId, vendorModel)
                          }
                          sx={{
                            height: 32,
                            width: 32,
                            minWidth: 32,
                            padding: 0,
                          }}
                        >
                          <EditIcon
                            width={16}
                            height={16}
                            fill="currentColor"
                          />
                        </IonButtonMultiVariant>
                      </Box>
                    ),
                  };
                })}
              />
              {chargerTemplateLoading && <TableLoader rowCount={5} />}
              {!chargerTemplateLoading && chargertemplates.length === 0 && (
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    minHeight: 240,
                  }}
                >
                  <Typography>No records found.</Typography>
                </Box>
              )}
            </div>
          </Box>
        </Box>
      </Box>
      <IonSnackbar
        open={Boolean(snackbar.message && snackbar.severity)}
        onClose={() => setSnackbar({ message: "", severity: undefined })}
        severity={snackbar.severity}
        message={snackbar.message}
      />
      <Modal
        onClose={handleCloseModal(handleCloseChargerTemplateModal)}
        closeAfterTransition
        open={updateChargerTemplateModal}
      >
        <ChargerTemplateModal
          Id={vendorId || ""}
          Model={vendorModel || ""}
          modalOpen={updateChargerTemplateModal}
          handleClose={handleCloseChargerTemplateModal}
          onChargerTemplateAddCallback={onChargerTemplateAddCallback}
          chargertemplates={chargertemplates}
        />
      </Modal>
    </>
  );
}

export default ChargerTemplatesTable;
