import { nexusAPI } from "@utils/APIInterceptor";
import { ApiEndpoints, RESTServerRoute } from "../types/Server";
import {
  ChargerTemplate,
  ChargerTemplateDataResult,
  VendorCode,
} from "../types/ChargerTemplate";

const getChargerTemplates = async (payload?: { Search?: string }) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<ChargerTemplateDataResult>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.CHARGER_TEMPLATES}`,
      {
        params: payload,
      }
    );

    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};

const getChargerTemplate = async (payload: {
  vendorId: string;
  vendorModel: string;
}) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const { vendorId, vendorModel } = payload;
    const response = await nexusAPI(token).get<ChargerTemplate>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.CHARGER_TEMPLATES}/${vendorId}/${vendorModel}`
    );

    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const getVendorCodes = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get<VendorCode[]>(
      `${ApiEndpoints.REST_SECURED_API}/vendor-codes`
    );

    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const postVendorCodes = async (body: VendorCode) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).put<{ message: string }>(
      `${ApiEndpoints.REST_SECURED_API}/vendor-codes`,
      body
    );

    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};

const postChargerTemplate = async (body: Partial<ChargerTemplate>) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).put<ChargerTemplate>(
      `${ApiEndpoints.REST_SECURED_API}/${RESTServerRoute.CHARGER_TEMPLATES}`,
      body
    );

    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};

const ocpp = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    const response = await nexusAPI(token).get(
      `${ApiEndpoints.REST_SECURED_API}/ocpp-standard-parameters`
    );

    const { data } = response || {};
    return data;
  } catch (error: any) {
    throw error;
  }
};

export { getChargerTemplates, getChargerTemplate, postChargerTemplate, ocpp };
