import IonSnackbar from "@components/ionSnackbar";
import {
  AlertColor,
  Box,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { isSuperAdmin } from "@utils/authService";

import { Dispatch, Suspense, useState } from "react";
import {
  Link,
  Outlet,
  matchPath,
  useLocation,
  useOutletContext,
} from "react-router-dom";

const IonTabs = styled((props: any) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    height: "5px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
}));

const IonTab = styled((props: any) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.grey[600],
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  })
);

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

const CatalogueLayout = () => {
  const ORG_TABS = [
    ...(isSuperAdmin() ? [{ label: "Vendors", key: "vendor-codes" }] : []),
    ...(isSuperAdmin() ? [{ label: "Models", key: "models" }] : []),
  ];
  const theme = useTheme();

  const routeMatch = useRouteMatch([
    "/catalogue/vendor-codes",
    "/catalogue/models",
  ]);

  const [selectedTab, setSelectedTab] = useState(
    routeMatch?.pattern?.path || `/catalogue/${ORG_TABS[0]?.key || ""}`
  );
  const [snackbar, setSnackbar] = useState<{
    message: string;
    severity?: AlertColor;
  }>({
    message: "",
    severity: undefined,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.getContrastText(theme.palette.background.default),
        flexDirection: "column",
      }}
    >
      <Box sx={{ pt: 4, px: 4, pb: 2 }}>
        <Typography variant="subtitle2" fontSize={24} fontWeight={"bold"}>
          Charger Catalogues
        </Typography>
      </Box>
      <Box
        sx={{
          px: 4,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <IonTabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="custom sites tab"
        >
          {ORG_TABS.map((item, index) => {
            const { label, key } = item || {};
            return (
              <IonTab
                key={key}
                value={`/catalogue/${key}`}
                to={`./${key}`}
                label={label}
                component={Link}
              />
            );
          })}
        </IonTabs>
      </Box>
      <Box
        sx={{
          flex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Suspense fallback={null}>
          <Outlet context={{ setSnackbar }} />
        </Suspense>
      </Box>
      <IonSnackbar
        open={Boolean(snackbar.message && snackbar.severity)}
        onClose={() => setSnackbar({ message: "", severity: undefined })}
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </Box>
  );
};

export default CatalogueLayout;

export function useNewCatalogueContext() {
  return useOutletContext<{
    setSnackbar: Dispatch<
      React.SetStateAction<{
        message: string;
        severity?: AlertColor | undefined;
      }>
    >;
  }>();
}
