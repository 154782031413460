import {
  Checkbox as MCheckbox,
  FormControl,
  FormControlLabel,
  BaseTextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import { FieldProps } from "formik";
import { FC } from "react";
interface CheckBoxProps extends FieldProps, BaseTextFieldProps {
  onChange?: (event: boolean) => void;
}

export const CheckBox: FC<CheckBoxProps> = ({
  form,
  field,
  label,
  disabled,
  onChange = () => {},
}) => {
  const { setFieldValue, touched, errors } = form || {};
  const { name = "", value = false } = field || {};
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.checked);
    onChange(event.target.checked);
  };

  const touch = touched[name];
  const errorMsg = touch && errors && errors[name];
  const theme = useTheme();
  return (
    <FormControl sx={{ alignSelf: "center" }} error={Boolean(errorMsg)}>
      <FormControlLabel
        label={label}
        value={value}
        disabled={disabled}
        control={
          <MCheckbox
            onChange={(event) => handleChange(event)}
            checked={value}
          />
        }
      ></FormControlLabel>
      <Typography sx={{ fontSize: "12px", color: theme.palette.error.main }}>
        {errorMsg ? String(errorMsg) : ""}
      </Typography>
    </FormControl>
  );
};
