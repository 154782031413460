import React, {
  lazy,
  Suspense,
  ReactElement,
  useState,
  useEffect,
} from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import {
  canListAnalytics,
  canListFleets,
  canListHome,
  canListLogs,
  canListPricingDefinition,
  canListSessions,
  canListStations,
  canListTags,
  canListUsers,
  isAdmin,
  isBasic,
  isBusiness,
  isSuperAdmin,
} from "@utils/authService";
import { nexusDB } from "@utils/nexusDB";
import ChargerTemplatesScreen from "@screens/chargerTemplatesScreen";
import FleetDiscoverScreen from "@screens/fleetsScreen/FleetDiscoverScreen";
import FleetListScreen from "@screens/fleetsScreen/FleetListScreen";
import { useAppSelector } from "@utils/reduxHooks";
import CatalogueLayout from "@screens/catalogueScreen/catalogueLayout";
import CatalogueScreen from "@screens/catalogueScreen";
const OcpiScreen = lazy(() => import("@screens/ocpiScreen"));

const LoginScreen = lazy(() => import("@screens/loginScreen"));
const HomeScreen = lazy(() => import("@screens/homeScreen"));
const AnalyticsScreen = lazy(() => import("@screens/analyticsScreen"));

const StationsScreen = lazy(() => import("@screens/stationsScreen"));
const RevenueScreen = lazy(() => import("@screens/revenueScreen"));
const TagsScreen = lazy(() => import("@screens/tagsScreen"));
const CustomerScreen = lazy(() => import("@screens/customersScreen"));

const LogsScreen = lazy(() => import("@screens/logsScreen"));

const ProfileScreen = lazy(() => import("@screens/profileScreen"));
const TabsSettingLayout = lazy(
  () => import("@components/layouts/tabsSettingLayout")
);
const TabsOrgSettingLayout = lazy(
  () => import("@components/layouts/tabsOrgSettingsLayout")
);
const SessionsScreen = lazy(() => import("@screens/sessionsScreen"));

const IonDashLayout = lazy(() => import("@components/layouts/ionDashLayout"));
const TariffScreen = lazy(() => import("@screens/tariffScreen"));
const ManageUsersScreen = lazy(() => import("@screens/manageUsersScreen"));
const BusinessScreen = lazy(() => import("@screens/businessScreen"));
const TenantsScreen = lazy(() => import("@screens/tenantsScreen"));
const BrandScreen = lazy(() => import("@screens/brandScreen"));
const NotFound = lazy(() => import("@screens/notFound"));

const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

const DashboardRoute = ({
  path,
}: {
  path:
    | "home"
    | "stations"
    | "revenue"
    | "sessions/completed"
    | "sessions/active"
    | "fleets/list"
    | "fleets/discover"
    | "analytics"
    | "tags"
    | "logs"
    | "index"
    | "customers"
    | "tenants"
    | "catalogue"
    | "ocpi";
}) => {
  const [canListHomePermission, setCanListHomePermission] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { components } = useAppSelector((state) => state.tenant);
  const { fleets } = components || {};
  const { active: fleetActive } = fleets || {};

  useEffect(() => {
    async function checkPermission() {
      const data = await canListHome();
      setCanListHomePermission(data);
      setLoading(false);
    }
    checkPermission();
  }, []);

  const goToInitialRoute = () => {
    return canListHomePermission ? (
      <Navigate to={"/home"} replace />
    ) : (
      <Navigate to={"/stations"} replace />
    );
  };

  if (loading) {
    return null;
  }

  switch (path) {
    case "index":
      return !isSuperAdmin() ? (
        goToInitialRoute()
      ) : (
        <Navigate to={"/tenants"} />
      );
    case "home":
      return canListHomePermission ? (
        <HomeScreen />
      ) : (
        <Navigate to={"/stations"} replace />
      );
    case "revenue":
      return <RevenueScreen />;
    case "stations":
      return canListStations() ? <StationsScreen /> : goToInitialRoute();
    case "tags":
      return canListTags() ? <TagsScreen /> : goToInitialRoute();
    case "sessions/active":
      return canListSessions() ? (
        <SessionsScreen status="active" />
      ) : (
        goToInitialRoute()
      );
    case "sessions/completed":
      return canListSessions() ? (
        <SessionsScreen status="completed" />
      ) : (
        goToInitialRoute()
      );
    case "fleets/discover":
      return canListFleets() && fleetActive ? (
        <FleetDiscoverScreen />
      ) : (
        goToInitialRoute()
      );
    case "fleets/list":
      return canListFleets() && fleetActive ? (
        <FleetListScreen />
      ) : (
        goToInitialRoute()
      ); // ...will be changed to List
    case "analytics":
      return canListAnalytics() ? <AnalyticsScreen /> : goToInitialRoute();
    case "logs":
      return canListLogs() ? <LogsScreen /> : goToInitialRoute();
    case "customers":
      return <CustomerScreen />;
    case "tenants":
      return isSuperAdmin() ? <TenantsScreen /> : goToInitialRoute();
    case "catalogue":
      return isSuperAdmin() ? <CatalogueScreen /> : goToInitialRoute();
    case "ocpi":
      return isSuperAdmin() ? <OcpiScreen /> : goToInitialRoute();
  }
};

const OrgSettingsRoute = ({
  path,
}: {
  path: "tariff" | "index" | "layout" | "manage-users" | "business";
}) => {
  const [isSiteOwnerPermission, setIsSiteOwnerPermission] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function checkPermission() {
      try {
        const userSite = await nexusDB.userSites
          .where("owner")
          .equals(1)
          .first();
        setIsSiteOwnerPermission(!!userSite);
      } catch (error: any) {
        setIsSiteOwnerPermission(false);
      } finally {
        setLoading(false);
      }
    }
    checkPermission();
  }, []);

  const ORG_TABS = [
    ...(canListPricingDefinition() ? ["tariff"] : []),
    ...(canListUsers() ? ["manage-users"] : []),
    ...(isAdmin() || isSuperAdmin() || isBusiness() ? ["companies"] : []),
    ...(isAdmin() || isBusiness() || (isBasic() && isSiteOwnerPermission)
      ? ["business"]
      : []),
  ];

  if (loading) {
    return null;
  }

  if (!ORG_TABS.length) {
    return <NotFound />;
  }
  if (path === "index") {
    return <Navigate to={`./${ORG_TABS[0]}`} replace />;
  }
  if (path === "layout") {
    return <TabsOrgSettingLayout />;
  }
  if (ORG_TABS.includes(path)) {
    switch (path) {
      case "manage-users":
        return <ManageUsersScreen />;
      case "tariff":
        return <TariffScreen />;
      case "business":
        return <BusinessScreen />;
    }
  } else {
    return <NotFound />;
  }
};

const CatalogueRoute = ({
  path,
}: {
  path: "index" | "layout" | "models" | "vendor-codes";
}) => {
  const ORG_TABS = ["vendor-codes", "models"];
  if (path === "index") {
    return isSuperAdmin() ? (
      <Navigate to={`./${ORG_TABS[0]}`} replace />
    ) : (
      <Navigate to={"/home"} replace />
    );
  }
  if (path === "layout") {
    return isSuperAdmin() ? (
      <CatalogueLayout />
    ) : (
      <Navigate to={"/home"} replace />
    );
  }
  if (ORG_TABS.includes(path)) {
    switch (path) {
      case "models":
        return isSuperAdmin() ? (
          <ChargerTemplatesScreen />
        ) : (
          <Navigate to={"/home"} replace />
        );
      case "vendor-codes":
        return isSuperAdmin() ? (
          <BrandScreen />
        ) : (
          <Navigate to={"/home"} replace />
        );
    }
  } else {
    return <NotFound />;
  }
};

const RoutesView = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <IonDashLayout />
              </PrivateRoute>
            }
          >
            <Route
              index={true}
              element={
                <PrivateRoute>
                  <DashboardRoute path="index" />
                </PrivateRoute>
              }
            />
            <Route
              path="home"
              element={
                <PrivateRoute>
                  <DashboardRoute path="home" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="revenue"
              element={
                <PrivateRoute>
                  <DashboardRoute path="revenue" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="tags"
              element={
                <PrivateRoute>
                  <DashboardRoute path="tags" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="customers"
              element={
                <PrivateRoute>
                  <DashboardRoute path="customers" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="analytics"
              element={
                <PrivateRoute>
                  <DashboardRoute path="analytics" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="sessions/active"
              element={
                <PrivateRoute>
                  <DashboardRoute path="sessions/active" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="sessions/completed"
              element={
                <PrivateRoute>
                  <DashboardRoute path="sessions/completed" />
                </PrivateRoute>
              }
            ></Route>
            {/* will be replaced by List component */}
            <Route
              path="fleets/list"
              element={
                <PrivateRoute>
                  <DashboardRoute path="fleets/list" />
                </PrivateRoute>
              }
            />
            <Route
              path="fleets/discover"
              element={
                <PrivateRoute>
                  <DashboardRoute path="fleets/discover" />
                </PrivateRoute>
              }
            />
            <Route
              path="logs"
              element={
                <PrivateRoute>
                  <DashboardRoute path="logs" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="stations"
              element={
                <PrivateRoute>
                  <DashboardRoute path="stations" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="tenants"
              element={
                <PrivateRoute>
                  <DashboardRoute path="tenants" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="catalogue"
              element={
                <PrivateRoute>
                  <CatalogueLayout />
                </PrivateRoute>
              }
            >
              <Route
                index={true}
                element={
                  <PrivateRoute>
                    <CatalogueRoute path="index" />
                  </PrivateRoute>
                }
              />
              <Route
                path="models"
                element={
                  <PrivateRoute>
                    <CatalogueRoute path="models" />
                  </PrivateRoute>
                }
              />
              <Route
                path="vendor-codes"
                element={
                  <PrivateRoute>
                    <CatalogueRoute path="vendor-codes" />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="ocpi"
              element={
                <PrivateRoute>
                  <DashboardRoute path="ocpi" />
                </PrivateRoute>
              }
            ></Route>
            <Route
              element={
                <PrivateRoute>
                  <TabsSettingLayout />
                </PrivateRoute>
              }
              path="settings/personal"
            >
              <Route index={true} element={<Navigate to="profile" />} />
              <Route
                path="profile"
                element={
                  <PrivateRoute>
                    <ProfileScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              element={
                <PrivateRoute>
                  <OrgSettingsRoute path="layout" />
                </PrivateRoute>
              }
              path="settings/tenant"
            >
              <Route index={true} element={<OrgSettingsRoute path="index" />} />
              <Route
                path="tariff"
                element={
                  <PrivateRoute>
                    <OrgSettingsRoute path="tariff" />
                  </PrivateRoute>
                }
              />
              <Route
                path="manage-users"
                element={
                  <PrivateRoute>
                    <OrgSettingsRoute path="manage-users" />
                  </PrivateRoute>
                }
              />
              <Route
                path="business"
                element={
                  <PrivateRoute>
                    <OrgSettingsRoute path="business" />
                  </PrivateRoute>
                }
              />
            </Route>
          </Route>
          <Route path="login" element={<LoginScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default RoutesView;
