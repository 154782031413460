import { IonButton, IonIconButton } from "@components/ionButton";
import {
  AlertColor,
  Avatar,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Skeleton,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import IonageTooltip from "@components/ionTooltip";
import { ReactComponent as InfoIcon } from "@assets/materialIcons/info.svg";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import {
  FieldValueTypes,
  fieldNames,
  initialValues,
  fields,
  validationSchema,
  stationFields,
} from "./fields";
import { getContractById, patchContract, postContract } from "@nexusAPI/fleet";
import { getErrorMessage } from "@utils/APIInterceptor";
import AcitveSwitch from "./ActiveSwitch";
import IonSnackbar from "@components/ionSnackbar";
import { ReactComponent as POIAdd } from "@assets/icons/poi-add.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/delete.svg";
import Loader from "@components/loader";
import { Fleet } from "types/Fleet";
import { deepEqual } from "fast-equals";
import { canUpdateFleet, canUpdateStatus } from "@utils/authService";

const MODAL_SPACING = 48;
const HEADER_HEIGHT = 66;

interface FleetCardDetailsProps {
  modalOpen: boolean;
  handleClose: (event?: any) => void;
  fleetId: number;
  contractId?: number;
  fleetData?: Fleet;
  onContractAddCallback?: () => void;
}

const ContractModal = forwardRef(
  (
    {
      modalOpen,
      handleClose,
      fleetId,
      contractId,
      fleetData,
      onContractAddCallback,
    }: FleetCardDetailsProps,
    ref
  ) => {
    const theme = useTheme();
    const [isDedicatedAll, setIsDedicatedAll] = useState(true);
    const [loader, setLoader] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<{
      message: string;
      severity?: AlertColor;
    }>({
      message: "",
      severity: undefined,
    });
    const formRef = useRef<FormikProps<FieldValueTypes>>(null);

    const EditMode = !!contractId;

    const [apiInitialValues, setApiInitialValues] =
      useState<FieldValueTypes>(initialValues);

    const [contractData, setContractData] =
      useState<Awaited<ReturnType<typeof getContractById>>>();

    const handleGlobalCheck = (
      setFieldValue: (string: string, value: any) => void,
      values: {
        stations: {
          id: string;
          tariffId: string;
          restriction: string;
        }[];
      }
    ) => {
      const updatedStation = values.stations.map((list) => ({
        ...list,
        restriction: !isDedicatedAll ? "DEDICATED" : "SHARED",
      }));
      setFieldValue(fieldNames.stations, updatedStation);
      setIsDedicatedAll((prev) => !prev);
    };

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      checked
        ? formRef.current?.setFieldValue(fieldNames.status, "ACTIVE")
        : formRef.current?.setFieldValue(fieldNames.status, "INACTIVE");
    };

    const handleDedicatedAll = (value: boolean) => {
      setIsDedicatedAll(value);
    };

    const getOperationalSize = (size: string) => {
      if (+size < 101) {
        return "0-100";
      } else if (+size < 500) {
        return "101-500";
      } else {
        return "500+";
      }
    };

    const getContractDetails = useCallback(
      async (filters: Parameters<typeof getContractById>[0]) => {
        setLoader(true);
        try {
          const response = await getContractById(filters);
          const {
            name,
            default_restriction,
            default_tariff_id,
            status,
            stations,
          } = response || {};
          if (!response) {
            throw new Error("Error occured");
          }
          const contractValues: FieldValueTypes = {
            [fieldNames.name]: name,
            [fieldNames.defaultTariffId]: default_tariff_id,
            [fieldNames.defaultRestriction]: default_restriction,
            [fieldNames.status]: status,
            [fieldNames.stations]:
              stations.map((station) => ({
                id: station.id,
                restriction: station.restriction,
                tariffId: station.tariff_id || "",
                tariffName: station.tariff_name || "",
                stationName: station.station_name || "",
              })) || [],
          };
          setApiInitialValues(contractValues);
          setContractData(response);
          return response;
        } catch (err) {
        } finally {
          setLoader(false);
        }
      },
      []
    );

    const updateContractForm = useCallback(
      async (contractId: number, values: FieldValueTypes) => {
        try {
          const {
            name,
            status,
            stations,
            defaultTariffId,
            defaultRestriction,
          } = values;
          let finalArray: any[] = [];
          let deletedFinalArray: string[] = [];

          for (let i = 0; i < stations.length; i++) {
            const { id } = stations[i] || {};
            const initialStations = contractData?.stations?.find(
              (initialStation) => initialStation.id === id
            );
            if (!deepEqual(stations[i], initialStations) && id) {
              finalArray.push({
                id: stations[i].id,
                restriction: stations[i].restriction,
                tariffId: stations[i].tariffId,
              });
            }
          }
          for (let i = 0; i < (contractData?.stations?.length || 0); i++) {
            const { id = "" } = contractData?.stations[i] || {};
            const finalStations = stations?.find(
              (finalStation) => finalStation.id === id
            );
            if (!finalStations) {
              deletedFinalArray.push(id);
            }
          }
          const response = await patchContract(
            { fleetId, contractId },
            {
              name,
              status,
              defaultTariffId,
              defaultRestriction,
              ...(finalArray.length ? { updatedStations: finalArray } : {}),
              ...(deletedFinalArray.length
                ? { deletedStationIds: deletedFinalArray }
                : {}),
            }
          );
          if (!response) {
            throw new Error("Error while creating contract form");
          }
          setSnackbar({
            message: "Updating contract is done",
            severity: "success",
          });
          setTimeout(() => {
            onContractAddCallback && onContractAddCallback();
          }, 1000);
        } catch (err: any) {
          const message = getErrorMessage(err, "updateContract");
          setSnackbar({ message, severity: "error" });
        }
      },
      [contractData?.stations, fleetId, onContractAddCallback]
    );
    const createContractForm = useCallback(
      async (values: FieldValueTypes) => {
        try {
          const {
            name,
            status,
            stations,
            defaultTariffId,
            defaultRestriction,
          } = values;

          let finalArray: any[] = [];
          for (let i = 0; i < stations.length; i++) {
            const { id } = stations[i] || {};
            if (id) {
              finalArray.push({
                id: stations[i].id,
                restriction: stations[i].restriction,
                tariffId: stations[i].tariffId,
              });
            }
          }

          const response = await postContract(
            { fleetId },
            {
              name: name,
              defaultRestriction,
              defaultTariffId,
              ...(finalArray.length ? { stations: finalArray } : {}),
              status,
            }
          );
          if (!response) {
            throw new Error("Error while creating contract form");
          }
          setSnackbar({
            message: "Creating new contract is done",
            severity: "success",
          });
          setTimeout(() => {
            onContractAddCallback && onContractAddCallback();
          }, 3000);
        } catch (err: any) {
          const message = getErrorMessage(err, "createContract");
          setSnackbar({ message, severity: "error" });
        }
      },
      [fleetId, onContractAddCallback]
    );
    const onFormSubmit = async (values: FieldValueTypes) => {
      if (EditMode) {
        updateContractForm(contractId, values);
      } else {
        createContractForm(values);
      }
    };

    useEffect(() => {
      if (contractId) {
        getContractDetails({ fleetId, contractId });
      }
    }, [contractId, getContractDetails, fleetId]);
    const {
      name = "",
      vehicle_types = [],
      operational_regions = [],
      operational_size = "",
      category = "",
      logo_url,
    } = fleetData || {};

    return (
      <Slide in={modalOpen} direction="up" ref={ref} tabIndex={-1}>
        <Container
          disableGutters
          component={"main"}
          maxWidth={false}
          sx={{
            outline: "none",
            position: "absolute",
            top: MODAL_SPACING,
            bottom: MODAL_SPACING,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: 1100,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: 2,
              background: theme.palette.background.paper,
              color: theme.palette.getContrastText(
                theme.palette.background.paper
              ),
              boxShadow: theme.shadows[1],
            }}
          >
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                <Typography fontWeight={700} variant="h6" mr={0.5}>
                  {EditMode ? "Edit Contract" : "Add Contract"}
                </Typography>
                <IonageTooltip
                  sx={{
                    "& ..MuiTooltip-popper": {
                      background: "#fff",
                    },
                  }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 0],
                        },
                      },
                    ],
                  }}
                  title={
                    <Typography
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      "You will be onboarding this fleet by creating a contract
                      and hence establishing a partnership with them"
                    </Typography>
                  }
                  placement="right"
                >
                  <InfoIcon
                    fill="currentColor"
                    width={18}
                    height={18}
                    style={{ opacity: 0.8, marginLeft: "8px" }}
                  />
                </IonageTooltip>
              </Box>
              {!loader && (
                <Box>
                  <AcitveSwitch
                    handleSwitch={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => handleSwitch(event)}
                    initialState={
                      contractData
                        ? contractData?.status === "ACTIVE"
                        : undefined
                    }
                    disabled={
                      !canUpdateFleet() ||
                      !canUpdateStatus(contractData?.status)
                    }
                  />
                </Box>
              )}
              {!!EditMode &&
                (loader ? (
                  <Skeleton width={300} />
                ) : (
                  <Box sx={{ display: "flex", mr: 2 }}>
                    <Typography
                      sx={{ fontSize: "12px", mr: 1 }}
                      color={theme.palette.grey[600]}
                    >
                      Created At:
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{ fontSize: "12px" }}
                      color={theme.palette.grey[600]}
                    >
                      {contractData?.created_at
                        ? `${new Date(
                            contractData?.created_at
                          )?.toLocaleDateString("en-IN", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "2-digit",
                          })} ${new Date(
                            contractData?.created_at
                          )?.toLocaleTimeString("en-IN", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}`
                        : ""}
                    </Typography>
                  </Box>
                ))}
              <IonIconButton color="inherit" size="small" onClick={handleClose}>
                <CloseIcon fill={"currentColor"} width={24} height={24} />
              </IonIconButton>
            </Box>
            <Box
              sx={{
                maxHeight: `calc(100vh - ${HEADER_HEIGHT + 2 * MODAL_SPACING}px)`,
                overflowY: "hidden",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mx: 2,
                  background: "#fff",
                  px: 2,
                  py: 0.5,
                  borderRadius: 2,
                  mb: 4,
                }}
              >
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Avatar src={logo_url} />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      mr: "6.5rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{ fontSize: "14px", mr: "4rem", whiteSpace: "nowrap" }}
                  >
                    {category}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{ fontSize: "14px", mr: "5rem", whiteSpace: "nowrap" }}
                  >
                    {vehicle_types}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{ fontSize: "14px", mr: "4rem", whiteSpace: "nowrap" }}
                  >
                    {getOperationalSize(operational_size)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {operational_regions[0]}
                    {operational_regions.length > 1 &&
                      " + " + (operational_regions.length - 1) + " more"}
                  </Typography>
                </Grid>
              </Grid>
              {loader ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "508.5px",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <Formik
                  innerRef={formRef}
                  initialValues={apiInitialValues}
                  validationSchema={validationSchema}
                  onSubmit={onFormSubmit}
                  enableReinitialize
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form id="Contract_form" autoComplete="off">
                        <Box
                          sx={{
                            display: "flex",
                            gap: 4,
                            mx: 2,
                            mb: 4,
                          }}
                        >
                          <Box sx={{ maxWidth: "300px" }}>
                            <Typography
                              component={"h1"}
                              sx={{ fontSize: "18px", fontWeight: 700, mb: 4 }}
                            >
                              Contract Details
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 4,
                              }}
                            >
                              {fields.map((item) => {
                                const {
                                  default_tariff_id = "",
                                  default_tariff_name = "",
                                } = contractData || {};
                                if (
                                  item.name === fieldNames.defaultRestriction
                                ) {
                                  return (
                                    <FormGroup
                                      key={item.name}
                                      sx={{
                                        "& .MuiFormControlLabel-root": {
                                          alignItems: "start",
                                          mr: 0,
                                        },
                                        "& .MuiCheckbox-root": {
                                          pt: "4px",
                                        },
                                        "& .MuiTypography-root": {
                                          color: theme.palette.grey[600],
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={isDedicatedAll}
                                            onChange={(event) =>
                                              handleGlobalCheck(
                                                setFieldValue,
                                                values
                                              )
                                            }
                                            disabled={!canUpdateFleet()}
                                          />
                                        }
                                        label={
                                          <Typography>
                                            By default, all assigned stations
                                            are
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                color: theme.palette.grey[800],
                                                margin: "0 4px",
                                              }}
                                            >
                                              dedicated
                                            </span>
                                            to this fleet only. Uncheck to make
                                            them shared and accessible to other
                                            fleets on a first come first serve
                                            basis.
                                          </Typography>
                                        }
                                      />
                                    </FormGroup>
                                  );
                                }
                                if (fieldNames.defaultTariffId === item.name) {
                                  return (
                                    <Field
                                      key={item.name}
                                      {...item}
                                      initialTariffValue={{
                                        tariffName: default_tariff_name,
                                        tariffId: default_tariff_id,
                                      }}
                                      disabled={!canUpdateFleet()}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          background:
                                            theme.palette.background.paper,
                                        },
                                      }}
                                    />
                                  );
                                }
                                return (
                                  <Field
                                    key={item.name}
                                    {...item}
                                    disabled={!canUpdateFleet()}
                                  />
                                );
                              })}
                            </Box>
                          </Box>
                          <Box flex={1}>
                            <FieldArray
                              name={fieldNames.stations}
                              render={({ push, remove }) => {
                                return (
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        mb: 2,
                                      }}
                                    >
                                      <Typography
                                        component={"h1"}
                                        sx={{
                                          fontSize: "18px",
                                          fontWeight: 700,
                                        }}
                                      >
                                        Stations List
                                      </Typography>
                                      <IonButton
                                        color="button"
                                        variant="contained"
                                        onClick={() => {
                                          push({
                                            id: "",
                                            tariffId: "",
                                            restriction: "DEDICATED",
                                          });
                                        }}
                                        disabled={
                                          !!values.stations.find(
                                            (station) => station.id === ""
                                          ) || !canUpdateFleet()
                                        }
                                        startIcon={
                                          <POIAdd
                                            fill="currentColor"
                                            height={24}
                                            width={24}
                                          />
                                        }
                                      >
                                        Add Station
                                      </IonButton>
                                    </Box>
                                    <Box
                                      sx={{
                                        background: "#fff",
                                        border: "0.5px solid #737373",
                                        padding: 2,
                                        borderRadius: 2,
                                        height: 360,
                                        overflow: "scroll",
                                      }}
                                    >
                                      <Grid container>
                                        {[
                                          { value: "Sl No", xs: 1 },
                                          { value: "Station", xs: 4 },
                                          { value: "Tariff", xs: 4 },
                                          { value: "Dedicated", xs: 2 },
                                        ].map((col, index) => (
                                          <Grid
                                            item
                                            xs={col.xs}
                                            key={index}
                                            mb={1.5}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: 600,
                                                fontSize: "14px",
                                              }}
                                            >
                                              {col.value}
                                            </Typography>
                                          </Grid>
                                        ))}
                                      </Grid>
                                      {values.stations.map((list, index) => {
                                        const {
                                          id = "",
                                          tariffId = "",
                                          stationName = "",
                                          tariffName = "",
                                        } = list || {};
                                        const { stations = [] } =
                                          contractData || {};
                                        const { id: existingId = "" } =
                                          stations.find(
                                            (station) => station.id === id
                                          ) || {};
                                        return (
                                          <Grid
                                            container
                                            spacing={2}
                                            mb={1.5}
                                            key={id}
                                          >
                                            <Grid
                                              item
                                              xs={1}
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "20px",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {index + 1}
                                              </Typography>
                                            </Grid>
                                            {stationFields.map((item) => {
                                              const {
                                                colCount,
                                                ...fieldProps
                                              } = item;
                                              if (
                                                fieldProps.name ===
                                                fieldNames.id
                                              ) {
                                                return (
                                                  <Grid
                                                    item
                                                    xs={colCount}
                                                    key={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                  >
                                                    <Field
                                                      {...fieldProps}
                                                      name={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                      initialStationValue={{
                                                        stationName:
                                                          stationName,
                                                        stationId: id,
                                                      }}
                                                      disabled={
                                                        !!existingId ||
                                                        !canUpdateFleet()
                                                      }
                                                      onChange={({
                                                        id,
                                                        label,
                                                      }: any) => {
                                                        setFieldValue &&
                                                          setFieldValue(
                                                            `${fieldNames.stations}[${index}].stationName`,
                                                            label
                                                          );
                                                      }}
                                                    />
                                                  </Grid>
                                                );
                                              }
                                              if (
                                                fieldNames.restriction ===
                                                fieldProps.name
                                              ) {
                                                return (
                                                  <Grid
                                                    item
                                                    xs={colCount}
                                                    key={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                    sx={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <Field
                                                      {...fieldProps}
                                                      name={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                      index={index}
                                                      handleDedicatedAll={
                                                        handleDedicatedAll
                                                      }
                                                      disabled={
                                                        !canUpdateFleet()
                                                      }
                                                    />
                                                  </Grid>
                                                );
                                              }
                                              if (
                                                fieldNames.tariffId ===
                                                fieldProps.name
                                              ) {
                                                return (
                                                  <Grid
                                                    item
                                                    xs={colCount}
                                                    key={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                  >
                                                    <Field
                                                      {...fieldProps}
                                                      name={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                      initialTariffValue={{
                                                        tariffName: tariffName,
                                                        tariffId: tariffId,
                                                      }}
                                                      disabled={
                                                        !canUpdateFleet()
                                                      }
                                                      onChange={({
                                                        label,
                                                      }: any) => {
                                                        setFieldValue &&
                                                          setFieldValue(
                                                            `${fieldNames.stations}[${index}].tariffName`,
                                                            label
                                                          );
                                                      }}
                                                    />
                                                  </Grid>
                                                );
                                              }
                                              return (
                                                <Grid
                                                  item
                                                  xs={colCount}
                                                  key={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                >
                                                  <Field
                                                    {...fieldProps}
                                                    name={`${fieldNames.stations}[${index}].${fieldProps.name}`}
                                                    setFieldValue
                                                    sx={{
                                                      "& .MuiOutlinedInput-root":
                                                        {
                                                          background:
                                                            theme.palette
                                                              .background.paper,
                                                        },
                                                    }}
                                                  />
                                                </Grid>
                                              );
                                            })}
                                            <Grid
                                              item
                                              xs={1}
                                              sx={{ display: "flex" }}
                                            >
                                              <IonButton
                                                onClick={() => {
                                                  remove(index);
                                                }}
                                                disabled={
                                                  values.stations.length ===
                                                    1 || !canUpdateFleet()
                                                }
                                              >
                                                <DeleteIcon fill="currentColor" />
                                              </IonButton>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Box>
                                  </Box>
                                );
                              }}
                            ></FieldArray>
                          </Box>
                        </Box>
                        <Grid container spacing={2} mb={4} px={2}>
                          <Grid item xs={8}></Grid>
                          <Grid item xs={2}>
                            <IonButton
                              variant="outlined"
                              onClick={handleClose}
                              fullWidth
                              color="button"
                              aria-label="Discard"
                              sx={() => ({ height: 48 })}
                            >
                              Cancel
                            </IonButton>
                          </Grid>
                          <Grid item xs={2}>
                            <IonButton
                              variant="contained"
                              type="submit"
                              fullWidth
                              color="button"
                              sx={() => ({ height: 48 })}
                              disabled={
                                deepEqual(apiInitialValues, values) ||
                                !!values.stations.find(({ id }) => id === "")
                              }
                            >
                              Save
                            </IonButton>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Box>
          </Paper>
          <IonSnackbar
            open={!!snackbar.message && !!snackbar.severity}
            onClose={() => setSnackbar({ message: "", severity: undefined })}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        </Container>
      </Slide>
    );
  }
);

export default ContractModal;
