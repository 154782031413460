import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { BaseTextFieldProps } from "@mui/material";
import { FieldProps } from "formik";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "5px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
  },
  "& .MuiSwitch-track": {
    borderRadius: 18 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface CustomSwitchProps extends BaseTextFieldProps, Partial<FieldProps> {
  initialState?: boolean;
  onChange?: (value?: boolean) => void;
}

export default function CustomSwitch({
  disabled,
  label,
  form,
  field,
  onChange,
}: CustomSwitchProps) {
  const { setFieldValue } = form || {};
  const { name = "", value } = field || {};
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setFieldValue && setFieldValue(name, checked);
    onChange && onChange(checked);
  };
  return (
    <FormGroup
      sx={{
        "& .MuiFormControlLabel-root": {
          flexDirection: "row-reverse",
          justifyContent: "start",
        },
      }}
    >
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={value}
            onChange={handleChange}
            disabled={disabled}
            name={name}
            value={value}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
